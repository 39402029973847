import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, BrowserRouter, Routes } from "react-router-dom";
import { Home } from './pages/Home/Home';
import { VolumeOne } from './pages/VolumeOne/VolumeOne';
import { VolumeTwo } from './pages/VolumeTwo/VolumeTwo';
import { VolumeThree } from './pages/VolumeThree/VolumeThree';
import { VolumeFour } from './pages/VolumeFour/VolumeFour';
import Submit from './pages/Submit/Submit';
import Resouces from './pages/Resources/Resources';
import { Gooning } from './pages/Gooning/Gooning';

function App() {
  return (
    <div className="App">
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="/volume1" element={<VolumeOne />} />
          </Routes>
          <Routes>
            <Route path="/volume2" element={<VolumeTwo />} />
          </Routes>
          <Routes>
            <Route path="/volume3" element={<VolumeThree />} />
          </Routes>
          <Routes>
            <Route path="/volume4" element={<VolumeFour />} />
          </Routes>
          <Routes>
            <Route path="/resources" element={<Resouces />} />
          </Routes>
          <Routes>
            <Route path="/submit" element={<Submit />} />
          </Routes>
          <Routes>
            <Route path="/Notes-on-Gooning-w-JV-Marx" element={<Gooning />} />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;

import React from 'react';

class ImageUpload extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {}
        
    }

    showWidget = () => {

        let widget = window.cloudinary.createUploadWidget({ 
            cloudName: `sensicon`,
            uploadPreset: `ckdrm38h`,
            sources: ["local", "url", "camera", "dropbox", "facebook", "instagram", "google_drive"],
            styles: {
                palette: {
                    window: "#E9AFC2",
                    windowBorder: "#E9AFC2",
                    tabIcon: "#E9AFC2",
                    menuIcons: "#FFFFFF",
                    textDark: "#FFFFFF",
                    textLight: "#FFFFFF",
                    link: "#E9AFC2",
                    action: "#FF620C",
                    inactiveTabIcon: "#FFFFFF",
                    error: "#F44235",
                    inProgress: "#0078FF",
                    complete: "#20B832",
                    sourceBg: "#FFFFFF"
                }
            },
            tags: [this.props.name, this.props.email, this.props.username],
            context: {
                alt: this.props.words,
                Name: this.props.name,
                IG: '@' + this.props.username,
                Email: this.props.email
            }
            }, 
            (error, result) => {
                if (!error && result && result.event === "success") { 
                    console.log(result.info.url); 
        }}
        );
        
        widget.open()
        
        }
        
        render() {
        return (
            
            <div>
                 <button onClick={this.showWidget} id="attachButton" disabled={!this.props.name || !this.props.email || !this.props.words || !this.props.username}>Attach</button>
            </div>
            
        );
    }
}
export default ImageUpload;
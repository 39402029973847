import React from "react";
import NavigationBarBlur from "../../components/NavigationBarBlur/NavigationBar";
import Footer from "../../components/FooterAltBlack/Footer";
import { Button, Container } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import MagazineCover from '../../images/v4/mag.png'
import one from '../../images/v4/1.jpg'
import two from '../../images/v4/2.jpg'
import three from '../../images/v4/3.jpg'
import four from '../../images/v4/4.jpg'
import five from '../../images/v4/5.jpg'
import six from '../../images/v4/6.jpg'
import seven from '../../images/v4/7.jpg'
import eight from '../../images/v4/8.jpg'
import "./style.css"
import { Link } from "react-router-dom";

export const VolumeFour = () => {
    return(
        <div className="VolumeFour">
        &nbsp;
            <nav>     
                <NavigationBarBlur/>
                <Link to="/"><h1 id="h1-white">SENSITIVE CONTENT</h1></Link>
            </nav>
            <main id="magazineFeatures-4">
                <Container>
                        <Row>
                            <Col lg={true}>
                            <a href="https://www.happydevilboy.com/product/sensitive-content-magazine-vol-4" target="_blank" rel="noopener noreferrer"><img src={MagazineCover} alt="Magazine Cover" width="100%" /></a>
                            </Col>
                            <Col lg={true}>
                            <br/>
                            <h2>VOLUME 4</h2>
                            <p>
                            Kevin Leonardo, Cody Silver, Love Bailey's Savage Ranch, Amanda Lepore, Kyle Farmery, Jesse James Keitel, Jock Thomson, Medieval Censorship, Hayden Ira May's Deaf & Undressed, Homer & Sundial, Latin Dreams & 57 removed photos by 29 LGBTQ+ creators.
                                <br/>
                                <br/>
                                Includes a curated display of art and photography from LGBTQ+ creators, which have previously been censored or removed by social media platforms. The selection spans provocative and challenging visuals to those that stimulate discourse on the thin line of online censorship and comprises the core of Sensitive Content magazine.
                                <br/>
                                <br/>
                                @__dolce_paranoia__ @bae.bee_bleu @ben_r_artist @bla_vi8
@blindings0n @blvckmasq @breaweinreb @die__yung
@dommy_yumyum @everything_kills_2.0 @grossbleh 
@h_olograms @himboy_rope @jordanservicephotography
@kae.britton @mashgravity @metzxart @mikhamik52 
@mynegativefeelings @naturallynatey @olinschneeds
@roccosphotos @soerenbaptism @stupidinspace
@the.rubber.muscle.man @victorbambague @wildwestoftheeast @zacharyaaustin
                                <br/>
                                <br/>
                                Cover @thecoolestkev 
                                <br/>
                                <br/>
                                by @cchristophrr
                            </p>
                            <p>
                            6.5" x 9"<br/>
                            116 pages <br/>
                            Perfect bound <br/>
                            Printed in the USA
                            </p>
                            <a href="https://www.happydevilboy.com/product/sensitive-content-magazine-vol-4" target="_blank" rel="noopener noreferrer"><Button>BUY NOW</Button></a>
                            </Col>
                        </Row>
                </Container>
            </main>
            <div id="featuresImages">
                <img src={one} alt="4" width="100%"/>
                <img src={two} alt="wicxefniwpul8n9qlajm" width="100%"/>
                <img src={three} alt="MM1" width="100%"/>
                <img src={four} alt="six" width="100%"/>
                <img src={five} alt="folsom22" width="100%"/>
                <img src={six} alt="LoversInDoggy" width="100%"/>
                <img src={seven} alt="IMG_0017" width="100%"/>
                <img src={eight} alt="rvzxyueq6fadagnuythk" width="100%"/>
            </div>
            <Footer/>
        </div>
    )
}


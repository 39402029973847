import React from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import './style.css';
import FrontPageDesktop from '../../images/v4/kevinsite Large.jpg'
import FrontPageMobile from '../../images/v4/frontpage-mobile.jpg'
import FrontPageMedium from '../../images/v4/frontpage-medium.jpg'
import Footer from "../../components/Footer/Footer";
import MailChimpSignUp from "../../components/MailChimp/MailChimpSignUp";
import { Link } from "react-router-dom";

export const Home = () => {
    return(
        <div id="homePage">
        <nav id="header">     
            <NavigationBar/>
            <h1>SENSITIVE CONTENT</h1>
        </nav>
        <main>
        <Link to="/volume4"><img src={FrontPageDesktop} alt="Sensitive Content Volume 4 Cover" width={"100%"} id="desktop" /></Link>
        <Link to="/volume4"> <img src={FrontPageMobile} alt="Sensitive Content Volume 4 Cover"  id="mobile" /></Link>
        <Link to="/volume4"> <img src={FrontPageMedium} alt="Sensitive Content Volume 4 Cover" id="medium" /></Link>
           <MailChimpSignUp/>
        </main>
        <footer>
            <Footer/>
        </footer>
        </div>
    )
}
import React from "react";
import NavigationBarBlur from "../../components/NavigationBarBlur/NavigationBar";
import Footer from "../../components/FooterAlt/Footer";
import { Button, Container } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import MagazineCover from '../../images/senssy1.jpg'
import chainmom from '../../images/chainmom.jpg'
import ericlotzer from '../../images/ericlotzer.jpg'
import georgekanis from '../../images/georgekanis.jpg'
import gnarmitzvah from '../../images/gnarmitzvah.jpg'
import jao from '../../images/jao.jao.jao.jao.jao.jpg'
import leoxfoo from '../../images/leoxfoo.jpg'
import s3xorcist from '../../images/s3xorcist.jpg'
import sorendrawsguys from '../../images/sorendrawsguys.jpg'
import "./style.css"
import { Link } from "react-router-dom";

export const VolumeOne = () => {

    return(
        <div className="VolumeOne">
            &nbsp;
            <nav id="headerAlt">     
                <NavigationBarBlur page="VolumeOne"/>
                <Link to="/"><h1>SENSITIVE CONTENT</h1></Link>
            </nav>
            <main id="magazineFeatures">
                <Container>
                        <Row>
                            <Col lg={true}>
                            <a href="https://www.happydevilboy.com/product/sensitivecontent" target="_blank" rel="noopener noreferrer"><img src={MagazineCover} alt="Magazine Cover" width="100%" /></a>
                            </Col>
                            <Col lg={true}>
                            <br/>
                            <h2>VOLUME 1</h2>
                            <h3>Contemporary Censorship on Queer Media</h3>
                            <p>
                                A visual showcase of artwork and photos by LGBTQIA+ users that have been removed or restricted by social media platforms. Some images are abrasive and provoking, while others raise questions about the slippery slope of censorship online. SENSITIVE CONTENT aims to be a permanent home for posts that fell in between the virtual crack and were taken away by the limiting Community Guidelines.<br/><br/>
                                Featured users: @_thenameisraffo @adam___chuck @amadia_shadow_rabbit  @benjaminfredrickson2 @bigdumbtop @blake_gildaphish @cchristophrr  @chainmom @chrisbehroozian @cult_of_pan_2 @d3adboyfriend  @dakotanoot @djdinges @edlesh @ericlotzer @erikadrielpeterson @erminefeline @fish_the_clown @freshvenison @georgekanis @gnarmitzvah @grimreaper_prince @happydevilboy @horsegurlpress  @jao.jao.jao.jao.jao @jblokey @lady_stellz @leoxfoo @musklust @noiserover @nonethkins @nosnhojjj  @onlypeen @real_sick_puppy @rifyroyalty @robertandycoombs2 @s3xorcist @scottmabe @sorendrawsguys @stantastical @super.twunk @thatssoannpyne @the_queer_fix @the_roadkill_diaries @thenicoshow @vulgrpink @zachgrear
                            </p>
                            <p>
                            6.5" x 9"<br/>
                            116 pages <br/>
                            Perfect bound <br/>
                            Printed in the USA
                            </p>
                            <a href="https://www.happydevilboy.com/product/sensitivecontent" target="_blank" rel="noopener noreferrer"><Button>SOLD OUT</Button></a>
                            </Col>
                        </Row>
                </Container>
            </main>
            <div id="featuresImages">
                <img src={chainmom} alt="Chainmom" width="100%"/>
                <img src={ericlotzer} alt="ericlotzer" width="100%"/>
                <img src={georgekanis} alt="georgekanis" width="100%"/>
                <img src={sorendrawsguys} alt="georgekanis" width="100%"/>
                <img src={gnarmitzvah} alt="gnarmitzvah" width="100%"/>
                <img src={jao} alt="jao" width="100%"/>
                <img src={leoxfoo} alt="leoxfoo" width="100%"/>
                <img src={s3xorcist} alt="s3xorcist" width="100%"/>
            </div>
            <Footer page="VolumeOne"/>
        </div>
    )
}


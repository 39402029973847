import React from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBarBlur/NavigationBar';

const Resouces = () => {
    return(
        <>
         <nav id="headerAlt">   
            <NavigationBar />
            <Link to="/"><h1 style={{zIndex:"3"}}>SENSITIVE CONTENT</h1></Link>
        </nav>
        </>
    )
};

export default Resouces
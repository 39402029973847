import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBarBlur/NavigationBar';
import UploadWidget from '../../components/UploadWidget/UploadWidget'
import './style.css'

const Submit = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [words, setWords] = useState("");
    const [success, setSuccess] = useState("")

    function setField() {
        setName("")
        setEmail("")
        setUsername("")
        setWords("")
        setSuccess("Thank you!")
    }

    return(
        <>
        <nav id="headerAlt">   
            <NavigationBar />
            <Link to="/"><h1 style={{zIndex:"3"}}>SENSITIVE CONTENT</h1></Link>
        </nav>
        <main id="submitPage" style={{maxWidth:"820px"}}>
            <Container>
                <Row>
                    <h2>HI@SENSITIVECONTENTMAG.COM</h2>
                </Row>
                <Row>
                    <p>We're always looking for provocative artwork, erotic photography, and outrageous stories from queer voices. If you have an idea for an essay or want a chance to be featured in an upcoming volume of SENSITIVE CONTENT, please submit it below.</p>
                    <p>ALL FIELDS REQUIRED TO UPLOAD</p>
                </Row>
            </Container>
            <Container>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Row>
                            <Col>
                                <Form.Control 
                                    type="text"
                                    placeholder='Name'
                                    value={name}
                                    className="inputs"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Control 
                                    type="text"
                                    placeholder='Instagram Handle'
                                    value={username}
                                    className="inputs"
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                        <Row>
                            <Col>
                                 <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control 
                                    type="text"
                                    placeholder='Email'
                                    value={email}
                                    className="inputs"
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                                 </Form.Group>
                            </Col>
                            <Col>
                                &nbsp;
                            </Col>
                        </Row>
                        <Row>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Control 
                            as="textarea"
                            type="text"
                            value={words}
                            placeholder="Words"
                            className="inputs"
                            onChange={(e) => setWords(e.target.value)}
                            rows={3} />
                        </Form.Group>
                        </Row>
                </Form>
                <Row>
                    <UploadWidget name={name} email={email} username={username} words={words} />
                </Row>
                <Row>
                    <p>FILE UPLOAD MAX 10MB ONLY. PLEASE RESIZE BEFORE YOU SUBMIT.</p>
                </Row>
                <Button id="submitButton" onClick={() => setField()}>Submit</Button>
                <p>{success}</p>
            </Container>
        </main>
        </>
    )
}
export default Submit;
import React from "react";
import Mailchimp from 'react-mailchimp-form';
import './style.css';

const MailChimpSignUp = props => {
    return (
            <div id="newsletter">
                <p>JOIN OUR MAILING LIST</p>
                <Mailchimp
                    action={process.env.REACT_APP_MAILCHIMP_URL}
                    fields={[
                        {
                            name: 'EMAIL',
                            placeholder: 'EMAIL',
                            type: 'email',
                            required: true
                        },
                        {
                            name: 'FNAME',
                            placeholder: 'NAME',
                            type: 'text',
                            required: true
                        }
                    ]}
                    // Change predetermined language
                    messages={
                        {
                            sending: "Sending...",
                            success: "Thank you for subscribing!",
                            error: "An unexpected internal error has occurred.",
                            empty: "You must write an e-mail.",
                            duplicate: "Too many subscribe attempts for this email address",
                            button: "SUBSCRIBE"
                        }
                    }
                    // Add a personalized class
                    className='MailchimpStyle'
                />
            </div>
        )
 }

 export default MailChimpSignUp
import React from "react";
import NavigationBarBlur from "../../components/NavigationBarBlur/NavigationBar";
import Footer from "../../components/FooterAlt/Footer";
import { Button, Container } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import MagazineCover from '../../images/senssy2.jpg'
import Four from '../../images/42.jpg'
import wicxefniwpul8n9qlajm from '../../images/wicxefniwpul8n9qlajm.jpg'
import six from '../../images/06.jpg'
import MM1 from '../../images/M&M1.jpg'
import LoversInDoggy from '../../images/LoversInDoggy.jpg'
import IMG_0017 from '../../images/IMG_0017.jpeg'
import rvzxyueq6fadagnuythk from '../../images/rvzxyueq6fadagnuythk.jpg'
import folsom22 from '../../images/folsom-22.jpg'
import "./style.css"
import { Link } from "react-router-dom";

export const VolumeTwo = () => {
    return(
        <div className="VolumeTwo">
        &nbsp;
            <nav id="headerAlt">     
                <NavigationBarBlur/>
                <Link to="/"><h1>SENSITIVE CONTENT</h1></Link>
            </nav>
            <main id="magazineFeatures">
                <Container>
                        <Row>
                            <Col lg={true}>
                            <a href="https://www.happydevilboy.com/product/sensitive-content-vol-2" target="_blank" rel="noopener noreferrer"><img src={MagazineCover} alt="Magazine Cover" width="100%" /></a>
                            </Col>
                            <Col lg={true}>
                            <br/>
                            <h2>VOLUME 2</h2>
                            <p>
                                A visual showcase of artwork and photos by LGBTQIA+ users that have been removed or restricted by social media platforms. Some images are abrasive and provoking, while others raise questions about the slippery slope of censorship online. SENSITIVE CONTENT aims to be a permanent home for posts that fell in between the virtual crack and were taken away by the limiting Community Guidelines.
                                <br/>
                                <br/>
                                Qiumao, The Leather Gentleman, <Link className={"gooningLink"} to="/Notes-on-Gooning-w-JV-Marx">Notes on Gooning" w/ JV Marx</Link>, Andrew Sa, Rick Castro, Dominique Taylor Hildebrand, Joel Someone, Brontez Purnell, "Chris goes to Folsom", Austin Nolan, "A Fine, Sexy Line" w/ Nate Stetson & 63 removed photos by 29 LGBTQ+ creators.
    <br/><br/>
                                @12515.7 @antochrist @antwanithompson ®art_by_dan_ @benzowashere @byronscotadams @danauslo @danpony @depaulvera @domiuh @emumujuju @eratic @imscarlettgray @jugodepapaya @kylequinnnn @leo_maki
                                @littleeggboy @lukemaddaford @m.wilky @marbsworld @mat_j_scott @mrsethcorbin @mxdritns @pattyps_patpat @pup.riley @raredankart @rickcastro @robertflyntfoto @zackpetot
                                <br/>
                                <br/>
                                Cover @domiuh & @brontezpurnell
                            </p>
                            <p>
                            6.5" x 9"<br/>
                            116 pages <br/>
                            Perfect bound <br/>
                            Printed in the USA
                            </p>
                            <a href="https://www.happydevilboy.com/product/sensitive-content-vol-2" target="_blank" rel="noopener noreferrer"><Button>BUY NOW</Button></a>
                            </Col>
                        </Row>
                </Container>
            </main>
            <div id="featuresImages">
                <img src={Four} alt="4" width="100%"/>
                <img src={wicxefniwpul8n9qlajm} alt="wicxefniwpul8n9qlajm" width="100%"/>
                <img src={MM1} alt="MM1" width="100%"/>
                <img src={six} alt="six" width="100%"/>
                <img src={folsom22} alt="folsom22" width="100%"/>
                <img src={LoversInDoggy} alt="LoversInDoggy" width="100%"/>
                <img src={IMG_0017} alt="IMG_0017" width="100%"/>
                <img src={rvzxyueq6fadagnuythk} alt="rvzxyueq6fadagnuythk" width="100%"/>
            </div>
            <Footer/>
        </div>
    )
}


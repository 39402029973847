import React from "react";
import './style.css'

const Footer = props => {
    return(
        <>
       <div id="footer">
           <a href="https://www.happydevilboy.com/product/sensitive-content-magazine-vol-4" target="_blank" rel="noopener noreferrer">BUY VOL. 4</a>
           <p id="trademark">Copyright © 2024 Sensitive Content Magazine. All rights reserved.</p>
        </div>
        </>
    )

}

export default Footer
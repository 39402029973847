import React from "react";
import NavigationBarBlurBlack from "../../components/NavigationBarBlurBlack/NavigationBarBlack";
import Footer from "../../components/FooterAltBlack/Footer";
import { Button, Container } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import MagazineCover from '../../images/senssy3.png'
import one from '../../images/v3/1.jpg'
import two from '../../images/v3/2.jpg'
import three from '../../images/v3/3.jpg'
import four from '../../images/v3/4.jpg'
import five from '../../images/v3/5.jpg'
import six from '../../images/v3/6.jpg'
import seven from '../../images/v3/7.jpg'
import eight from '../../images/v3/8.jpg'
import "./style.css"
import { Link } from "react-router-dom";

export const VolumeThree = () => {
    return(
        <div className="VolumeThree">
        &nbsp;
            <nav id="headerAlt-3">     
                <NavigationBarBlurBlack/>
                <Link to="/"><h1>SENSITIVE CONTENT</h1></Link>
            </nav>
            <main id="magazineFeatures-3">
                <Container>
                        <Row>
                            <Col lg={true}>
                            <a href="https://www.happydevilboy.com/product/sensitive-content-magazine-vol-3" target="_blank" rel="noopener noreferrer"><img src={MagazineCover} alt="Magazine Cover" width="100%" /></a>
                            </Col>
                            <Col lg={true}>
                            <br/>
                            <h2>VOLUME 3</h2>
                            <p>
                            Avalon World w/ Symone, Gigi Goode & Rylie, Shea Couleé, Willow Pill, Denali, Bill Crisafi, Louisianna Purchase, J Davies, Buck Angel, Mino Sanchez, Daniel Rajcsanyi, Brett Loudermilk, Jonathan Colin, Southern Discomfort & 69 removed photos by 26 LGBTQ+ creators.
                                <br/>
                                <br/>
                                A curated display of art and photographs from LGBTQ+ creators, which have previously been censored or limited by social media platforms, comprises the core of Sensitive Content magazine. The selection spans provocative and challenging visuals to those that stimulate discourse on the thin line of online censorship. The magazine seeks to serve as a steadfast repository for creations that were overlooked or removed due to stringent Community Guidelines, ensuring these voices find a home away from the transient and restricted online arena.<br/><br/>
                                @_kevinbennett @armandocabba @bammer47 @braxtonmann @colinmemaybe @danielrajcsanyi  @grossbleh @ignacio.henriquez.c @j.criscitello  @jdavies.studio @jugodepapaya @lancel_t @lauraleebenjamin.com @marthyev @nikola.josip.stone @oat_montien @rainn _jackson @ryanhoneybaby  @sammysinn69 @sighber @southpawsf.2 @trevorxnathan @tueresmireligion @wrist_cramps @zachgrear @zestybob
                                <br/>
                                <br/>
                                Cover @thehouseofavalon @rylie4ever @the_symone @thegigigoode @grantvanderbilt @huntercrenshaw @marko_monroe @calebf 
                                <br/>
                                <br/>
                                By @loudermilk
                            </p>
                            <p>
                            6.5" x 9"<br/>
                            116 pages <br/>
                            Perfect bound <br/>
                            Printed in the USA
                            </p>
                            <a href="https://www.happydevilboy.com/product/sensitive-content-magazine-vol-3" target="_blank" rel="noopener noreferrer"><Button>BUY NOW</Button></a>
                            </Col>
                        </Row>
                </Container>
            </main>
            <div id="featuresImages">
                <img src={one} alt="4" width="100%"/>
                <img src={two} alt="wicxefniwpul8n9qlajm" width="100%"/>
                <img src={three} alt="MM1" width="100%"/>
                <img src={four} alt="six" width="100%"/>
                <img src={five} alt="folsom22" width="100%"/>
                <img src={six} alt="LoversInDoggy" width="100%"/>
                <img src={seven} alt="IMG_0017" width="100%"/>
                <img src={eight} alt="rvzxyueq6fadagnuythk" width="100%"/>
            </div>
            <Footer/>
        </div>
    )
}


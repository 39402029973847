import React from "react";
import NavigationBarBlur from "../../components/NavigationBarBlur/NavigationBar";
import Footer from "../../components/FooterAlt/Footer";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import "./style.css"
import { Link } from "react-router-dom";
import GooningHero from '../../images/JV-Marx-Notes-on-Gooning-0.jpg';
import GooningHeroMobile from '../../images/JV-Marx-Notes-on-Gooning-0-mobile.jpg';
import GooningOne from '../../images/JV-Marx-Notes-on-Gooning-1.jpg'
import GooningTwo from '../../images/JV-Marx-Notes-on-Gooning-2.jpg'
import GooningThree from '../../images/JV-Marx-Notes-on-Gooning-3.jpg'

export const Gooning = () => {
    return(
        <div className="Gooning">
            &nbsp;
            <nav id="headerAlt">     
                <NavigationBarBlur/>
                <Link to="/"><h1>SENSITIVE CONTENT</h1></Link>
            </nav>
            <div id="gooningHero">
                <img src={GooningHero} width="100%" alt="JV-Marx-Notes-on-Gooning-0" id="goon-desktop"/>
                <img src={GooningHeroMobile} width="100%" alt="JV-Marx-Notes-on-Gooning-0-mobile" id="goon-mobile"/>
            </div>
            <Container id="Gooning-Words">
                    <Row>
                        <h1 id="gooning-h1">NOTES ON GOONING</h1>
                        <h2 className="gooning-links"><a href="https://www.instagram.com/hotmarkzucc/" target="_blank" rel="noopener noreferrer" style={{color: "#000000"}}>WORDS JV MARX</a></h2>
                        <h2 className="gooning-links"><a href="https://www.instagram.com/warrenpiece/" style={{color: "#000000"}} target="_blank" rel="noopener noreferrer">PHOTOS WARREN PIECE</a></h2>
                        <br/><br/>
                    </Row>
                    <Row>
                        <p>
                        Newcomers to gooning always have the same question: How do you do this?<br/> 
People who have tried it, and aren't satisfied with the results, have another: <br/>
How do you go deeper? How do you let go?<br/>
Interestingly, and perhaps disappointingly, there are few simple answers to these questions. After all, to describe how to do something, let alone do it “better,” you have to first define what that thing is.<br/>
<br/>
Gooning is tough to define. Gooning is many things. 
<br/>
For example, cumming all the time is goony. But so is not cumming at all. 
“Alpha males” do it; “beta-males” do it. <br/>
Girls, gays, theys, and people who don't even know they're “doing it” do it. <br/>
<br/>
It's solitary, It's communal. <br/>
It's holy, it's demonic. It's divine.<br/>
<br/>
There's a lot that's happening. <br/>
<br/>
<br/>
The talking points on gooning all run into one another, inform one another, and contradict one another. You'll find exclusionary lines of discourse and messy, messy tangents when you start at any one point and proceed in a straight line. Many of these topics could be their own books. Some are just fucking stupid. For that reason, and for the objective cringeyness of waxing poetic on drool, Notes on Gooning: <br/><br/>
1. Gooning's most prominent predecessor is perhaps ahegao, a Japanese style of pornography that focuses on a receptive/submissive sex partner making ecstatic, orgasmic facial expressions. Many of these expressions are similar to those you see in gooning: eyes crossed, drooling, tongue hanging out, acting wild, animalistic, overwhelmed, surprised, etc. Gooning by another name.<br/>
<br/>
Some differences: Ahegao tends to feature more themes of infantilization, helplessness, submissiveness, and cutesiness, and its subject matter is usually “feminized.” It's also primarily illustrated content, for cultural and political reasons. Gooning is webcam pornography, for the most part, and tends to be more primal and brutish. Also, Its subject matter and practitioners largely consist of cis men who lean into themes of masculinity. Not always the case. <br/>
<br/>
The bigger difference, however, is that gooning is primarily a masturbation kink. Ahegao, while featuring masturbation content, is more inclusive towards penetrative play, or the influence of a partner in some kind of receptive fashion. <br/>
<br/>
Gooning is different. It's rarely involved in sex. (Unless you're calling just jerking off sex, which tons of gooners do).<br/><br/>
2. The terms “bator” and “gooner” can be used sort of interchangeably. All gooners are bators, not all bators are gooners. Bators like to masturbate a bunch, pretty self-explanatory. <br/><br/>
3. Let's repeat here, right away, that girls goon. Trans people goon. Non-binary people goon. I know them, I've met them, I've jerked off to their stuff. Sometimes they go by “girl goon” or “goonette.” Regardless of what you call it, or how the goony behavior manifests, the unifying experience that lies beneath is the radical embrace of masturbation as a valid, fully realized sexual experience. Anyone can do this. <br/>
<br/>
Gooning is often an expression of gender euphoria. In practice, that expression has largely been that of cis men affirming their cis manhood. There are obvious reasons why that has been the case, cis men are annoyingly dominant in most spaces they occupy. But there are also more interesting reasons. <br/> <br/>
4. The term “goon” has always been married to themes of toxic masculinity and other problematics. It is a gendered term. Earliest uses of the word (1500's) were used to describe the oafish, the foolish, and people with developmental disorders as derogatory slang. <br/>
<br/>
Later, around the turn of the 20th century, the term was applied to gangsters, hired muscle, henchmen, union enforces, and people with a lot of physical brawn. Physically imposing, stupid, violent, brutish, and clumsy individuals. <br/>
<br/>
For example, hockey brawlers are often referred to as the teams “goon.” <br/>
<br/>
<img src={GooningOne} width="100%" alt="JV-Marx-Notes-on-Gooning-1"/>
<br/>
<br/>
Many gooners flex their muscles, focus on getting them bigger. Becoming a himbo. Bulking. Getting hairier. Dirtier. Muskier. Etc. A masculine lifestyle, drinking beer, wearing sneakers, smoking weed, a messy room, jerking on the couch watching sports, it's all goony stuff. “Guys being guys.”<br/>
<br/>
An offshoot here we can mention would be “reek-bating” – a kink where dudes sniff each others pitts, balls, asses, whatever, while they jerk off. Pretty much all goons are into armpits and stuff, but “reekbators” take that shit seriously. <br/>
<br/>
On the flipside, the goon that expresses more femininity might lean into more stereotypically “feminine” qualities, outfits, motifs, etc. We see this in ahegao, as mentioned above, and femboy/e-girl pornography. Pig tails, gamer-girl stuff, “pretty please” eyes, blushing, make-up getting messy, the works. Or not. “Masculine” and “feminine” qualities are up for grabs in terms of owning them, performing them, subverting them, etc.<br/>
<br/>
The female performance in Gonzo porn is also very goony. So is the guys, I suppose. It's more like the “America, Fuck Yeah” response to ahegao. Gonzo is responsible for a lot of shifts in contemporary pornography, and we don't have the time and space to talk about it here. Suffice to say that it introduced a lot of raunchiness, sloppiness, closeup shots, homoeroticism in the male gaze, and the fisheye lens. Do some googling. <br/>
<br/>
5. Gooning/Bating lends itself to offshoots and variations. Along with “reekbators,” you have “monkeybators,” (they act like monkeys while they jerk off), “bator-pups,” (human pups that also like to bate), etc. etc. Since it's a masturbation kink, and masturbation lends well to fantasies and engaging with extra material that makes you cum, one can kinda suit the word to the action, the action to the word. <br/>
<br/>
6. Another aspect of gooning's cis-male focus is born of something akin to privilege. Many men, as boys, are exposed to pornography and masturbation in a social context. They are encouraged to consume pornography on all fronts, and are largely given a green light to discuss and share those experiences with their peers. This doesn't always go well. Some are socialized by a masculine upbringing, many more are traumatized by it. Regardless, in our society, it is “okay” for cis men to be sexual – hyper-sexual, even. Jerking off is the kind of thing you can talk about like, in the cafeteria in 7th grade with all your friends.<br/>
<br/>
Women experience this kind of socialization as well, but in different ways, and far less often, and do not have the same kinds of privileges to do anything sexual, let alone anything that could be perceived as homoerotic, without consequence. Boys are bullied for being gay, and punished by parents and religious figures alike, true – but among their peers, typically: they're jerking off at the slumber party. Plus, like: grown men jerk off together. Among girls, adult women, discussing porn and masturbation can still be very taboo. Men act out. <br/>
<br/>
A kink like gooning, in its current form, can only really take shape when you have the space to develop a culture surrounding masturbation and owning your sexuality in a platonic, social sense. Societally, cis men have had a head start on that development.<br/>
<br/>
7. Here, we can note that one goons “with” someone, “for” someone, or “to” something.  
With: The term “side” comes from guys who seek to bate “with” people. It's like a “let's do this side by side and enjoy the same thing” vibe. This often comes from a place of pure platonic curiosity. Tons of bators are straight, heteroflexible, or bisexual. There isn't necessarily a sexual attraction to your partner, even if you're gay. <br/>
<br/>
The fantasy of “with” is often recreated through role-play. Many men miss the platonic possibilities of adolescence, or the closet, when they were able to comfortably enjoy masturbation with their peers. It's a valid third space of sexuality that is much harder to come by in regular adulthood. For me, one of the first things I knew about my sexuality was that I enjoyed jerking off with other guys. Gay sex was like, a totally different thing. It still is.  <br/>
<br/>
While your textbook role-plays are going on: best friends, bro/bro, dad/son, coach/athlete, doctor/patient – there is also a deeper sense of role-play that occurs in the online dating world. Some bators pretend to be straight, looking for only this kind of vibe for the day, and actually are extremely homo. Sometimes they want to create friendships, bring them around their friends, having “nobody know” that they're jerking off together on the DL. These bators miss the platonic third space, but they also miss the secrets. The rituals. In a contemporary world devoid of ritual for the sexual experience, especially in platonic and homoerotic contexts, we make our own. <br/>
<br/>
<img src={GooningTwo} width="100%" alt="JV-Marx-Notes-on-Gooning-2."/>
<br/><br/>
For: Gooning is exhibitionistic. Making a gooner face is hot and exciting alone in your bedroom, sure. But it's even more exciting if someone sees you doing that. Especially a lot of people you don't know.<br/>
<br/>
Most gooning probably occurs in online video chatrooms. Zoom, Skype, Bateworld (great place to meet gooners in your area), etc. It's a genre of pornography born from these kinds of webcams. My earliest exposure to gooning, and a lot of people's, I think, was Dustin Zito on Fratpad's shows. If you're into gooning, you know the one I'm talking about. Bathrobe open, dick in hand, horrified grimace on a super hot guy. Love it. <br/>
<br/>
Some bators seek out doms, “bate” coaches, or “popper” coaches that you goon for. This is another point of advice for those seeking to go further, especially if you're shy, or have sub tendencies. Gooning is humiliating, and being humiliated by someone can help. Or encouraged. Most doms are going to call you names and stuff, but there are plenty of coaches out there that can just get you wild on a more friendly, encouraging note. Communicate your needs. They can tell you when to stroke, how to stroke, how fast, when to stop, when to hit poppers, smoke weed, cum, not cum, whatever. It takes a lot of pressure off the sub, and allows you to let go. <br/>
<br/>
To: Gooners are obsessed with porn. Some identify as “solo-sexual” or “porno-sexual.” Much of the content made for/about gooners (“bate fuel”) leans into that. They are often simply captioned stills (“gooner caps”) or video compilations (“popper trainers”) that encourage masturbation, pump your ego, humiliate you, whatever the variation on theme requires. It's giving A Clockwork Orange: quick cuts, brainwashy, instructional, drug- involved. <br/>
<br/>
You see a lot of celebrity porn in these contexts. “Goon to Justin Beiber's Hairy Armpits,” “Ross Lynch rules your brain” Or it's a shot of some celeb like Jon Hamm (very goony face) with the caption “Gooners don't cum,” “Haha, look at you with your peepee all sore, keep going,” “Gooning to feet pics? Wow loser, keep going.” Etc. Spending more time gooning “to” pornography is one of the axioms of the kink. <br/>
<br/>
This kind of porn usually highlights the patheticness of addiction. Sometimes, the images are censored, fetishizing a withholding of pornography from the gooner's brain. One time, I saw an account that was encouraging people to jerk off to pictures of carpets. Something for everyone, I suppose. <br/><br/>
8. The connection to problematics is hardly a thing of the past. Unfortunately, many bators today choose to use derogatory language and perform hyper-sexualized caricatures of neurodivergent people as a way to goon. Making these faces, they lean into the idea of getting “stoopid” and “downshifting” into the gooner headspace. Perhaps that very idea of acting stupid is inherently offensive. I'm not so sure. Foolishness is one thing, stupidity is another. We should be careful. It's an offensive world.<br/>
<br/>
Gooning inherently breaks taboos. You're not “supposed” to make faces like these or express yourself in this way, especially during masturbation. Breaking that first taboo of “taking off your human suit” emboldens some to break others. Using derogatory slang is just one example. <br/>
<br/>
Gooning can be very nihilistic, offensive, self-destructive, and amoral. People fetishize their demise, their addictions, the addictions of others, hurt themselves, spend all their money, overdose, ruin relationships, take race-play a little too far, and so forth. It's a slippery slope.  <br/><br/>
Be careful. Take care of each other. While breaking certain taboos and acting wildly is inherent to gooning as a kink, offending people and creating real-world harm is not. Not for yourself, not for other people. There's a strong temptation, in a world so fucked up, to just close the blinds and fuck everything up yourself. This is part of gooning's legacy, and just one note of its post-modern aspects. <br/>
<h1 id="gooning-pull">MAKE A FACE, SEE HOW YOU FEEL.</h1>
9. Deconstruction of the body is another one of those aspects. The gooner becomes their genitals. The body is a machine that makes cum. Aesthetically, on camera, the top of the head is often cut off. Like something out of Beckett, all you see is the mouth. This is for anonymity's sake, mostly. But its visual qualities are also worth noting. <br/><br/>
10. Deconstruction of language is a third post-modern aspect. Gooning relies heavily on vocalization and text. It does so both in the actions of the kink and the types of pornography that are created for and from its practice. <br/>
<br/>
In terms of the voice, gooners use broken down, stupid, overly-clinical, immature, non- sexy, and cringe language for what they do while they do it. This is sometimes called “bator babble,” and often takes the form of a kind of speaking in tongues, or a chirping back-and- forth as a form of mutual encouragement: <br/>
<br/>
Penis, my peepee, my ding-dong, my boner, bro. Worship dong. Penising my penis. Up and down on the dong, dude, up and down. Penus. Weenur. I love you, penis. Dude this is my phallus. You're stroking your cock and i'm stroking my cock. My fuckstick. My boyboner. My erekkshun. Sperming my penis dude, semening. Etc.<br/>
<br/>
These examples are far from random, but rather, selections from a communal “script.” Gooners copy one another while they goon, both in terms of the faces they're making, poses they're taking (flexing, pointing at your junk), and most importantly, the things they're saying. Most of these examples above are things that are made popular in amateur pornography, and people have copied them because they're awesome. It's a small community. We're all watching the same shit. <br/><br/>
11. No bullshit: this is mantra territory. Bator babble deconstructs language, and brings you to the present moment. While the average meditator might focus on breath, the gooner focuses on penis. Make penis your mantra, “worship penis,” etc. Perhaps some gooners have made masturbation their religion, even as a joke, as a way to process the trauma of growing up in a culture that treats masturbation as a sin. Some just lean into that sin part and straight up worship Satan while they fap. Not exactly my thing, but interesting, nonetheless. <br/>
<br/>
Maybe they're both onto something. Some of gooning's faces look like demon masks from all kinds of cultures, lion's breath with yoga. Even the Catholics got horny for a second: look at The Ecstasy of St. Teresa. The Virgin Mary is often in a whole vaginal halo/shroud moment. <br/>
<br/>
There's something energetic/spiritual going on here, whether we “mean to do it” or not. A friend of mine, I forget who, sorry, once described gooning as Tantra devoid of spiritual context. Something to think about. <br/><br/>
12. Gooning is expressionistic. Expressionism is a lot of things, but one definition is something along the lines of: the most singular expression of the most singular emotion. I believe this definition is the invention of the late Maureen Shea, at Emerson College. I can't find it elsewhere. In any case, like in the works of Bacon, many gooners are experiencing/ expressing horror, anguish, and like, being crushed. It doesn't have to be horror to be expressionism, sometimes the singular expression can be bliss. Point is: at its core, gooning is about fully expressing overwhelming, typically singular experiences. Usually, that's stupidity or brutishness. <br/><br/>
13. Gooning is essentially mask work. Donning a mask changes you. Making a face on purpose changes your brain. It triggers your memory, your imagination, brings out different qualities in yourself. For this reason, I encourage gooners to make a silly face before they “feel like it.” Get ahead of your feelings. The mask is the gateway. <br/>
<br/>
This mask is one of ultra-expression and ultra-receptiveness. The mouth is open. The tongue is out, the eyes are wide open. Nostrils flare. The body is open, receptive to tastes, smells, sights. And at the same time, this open body expresses itself, pushing energy out at the same time as it takes energy in. Drool and loud noises come from the mouth. Breath. The eyes bulge, they cross, they act as seductors. <br/>

Make a face, see how you feel. <br/><br/>
14. Gooning is also clown-adjacent, especially among gooners who try to never cum, save their loads, and let their ugly faces show when they bate. The goon, like the clown, deals with a dominating force that they try to obey, whether that's a dom or your dong. For the clown, that's a senior clown. For the gooner, it's the porn, the edge, or the dom. When the gooner fails, or “relapses,” or “ruins his orgasm,” he's essentially doing a prat-fall. Pie in the face. Water-gun gag from the flower-pin. The act is all the more fantastic because of its failure. <br/>
<br/>
Gooning is theatrical.<br/><br/>
<img src={GooningThree} width="100%" alt="JV-Marx-Notes-on-Gooning-3"/>
<br/><br/>
15. Gooners use Albolene, or other greasy lubes, when they jerk it. Albolene is kind of canon. Water-based lubes, if you're using them for like, an hour of five, are going to make your hands all clammy. Silicone has to be re-applied too often for my taste. Use whatever you like. The grease feels hella good, and a little bit goes a long way in terms of not-rubbing- your-dick-raw. You don't wanna rub your dick raw. Plus, it's kinda filthy feeling.<br/><br/>
16. Next to (and before) crystal meth and marijuana, poppers are the drug of choice for the goon. When inhaled, they create a sense of euphoria and a massive lowering of your inhibitions. This can be helpful if you're too embarrassed to make these faces and say this stuff alone in your bedroom. Plus, they make you drool and cum pretty hard. Hot.<br/><br/>
They also can make your dick go soft for a sec. Not so hot. Try a cock ring.  <br/><br/>
In small amounts, poppers are safe to inhale. You cannot drink them, you should not get it on your skin (burns like the dickens), and you should not mix it with any other medication that lowers your blood pressure, like viagra. Poppers mixed with viagra will kill you. <br/>
<h1 id="gooning-pull">POPPERS MIXED WITH VIAGRA WILL KILL YOU.</h1>
Don't have your partner handle the bottle, if you can help it. Pouring this shit in your nose is a worst-case scenario, and with a greasy hand, it's easy to lose your grip. As a method of harm reduction, you can soak a cotton ball in the stuff and put it in its own little jar. This prevents any spilling from happening. <br/><br/>
17. Actual advice: mirroring is an excellent way to explore gooning. I mean, you could look in a mirror, but it's better with a bate buddy. They flex, you flex. They say penis, you say penis. By joining someone in an action, you become an empty vessel open to inspiration. This kind of thing tends to happen naturally without “discussing it” – but consciously getting into that space can be very helpful when trying to push past your inhibitions. Just copy someone. Its ridiculously similar to exercises actors use to get out of their heads and build ensemble energy. <br/><br/>
18. There's a preconceived notion that gooning is something that happens to you. In reality, it's something you do. This kink community is composed of a lot of different people doing a lot of different, highly specific things. When we're struggling to let go, we're often dealing with the unexamined bate. It's not worth having. <br/>
<br/>
What do you want to do? Where do you place your attention? What are you in relationship to? You have to make a choice, or else just blunder into one. But it's something you actually do, not something that happens to you. <br/>
<br/>
Plus, gooning “on purpose” is what makes it so hot, interesting, and political. <br/>
<br/>
Grab your dick. Grab your pussy. Put on some porn. Goon.  

                        </p>
                    </Row>
                </Container>
            <div id="gooning-footer">
                <Footer/>
            </div>
        </div>
    )
}
import React, {useState} from 'react';
import { Button, Container, Offcanvas, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HiddenEye from '../../images/noun-hidden-eye-718767-2-black.svg';
import InstagramLogo from '../../images/glyph-logo_May2016-black.svg';
import './style.css'

const NavigationBar = props => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
      <div id="offCanvas">
    <Button variant="none" onClick={handleShow}>
        <img id="hiddenEye" src={HiddenEye} alt="hidden eye"></img><br/><p>MENU</p>
    </Button>

    <Offcanvas show={show} onHide={handleClose} id="offMenu">
      <Offcanvas.Header closeButton closeVariant="white">
      </Offcanvas.Header>
      <Offcanvas.Body>
          <Container>
              <Row>
              <Stack gap={0}>
                    <Link to='/volume4'>VOL. 4</Link><br/>
                    <Link to="/volume3">VOL. 3</Link><br/>
                    <Link to="/volume2">VOL. 2</Link><br/>
                    <Link to='/volume1'>VOL. 1</Link><br/>
                    
                </Stack>
              </Row>
              <Row style={{ "position": "fixed", "bottom": "0px", "margin-bottom": "50px"}}>
                <Stack gap={1}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/sensitivecontentmag/"><img src={InstagramLogo} alt="Instagram" width="40px" /></a><br/>
                    <Link to='/submit'>SUBMIT</Link><br/>
                    <a href="https://www.happydevilboy.com/category/sensitive-content" target="_blank" rel="noopener noreferrer">BUY</a>
                </Stack>
              </Row>
          </Container>
      </Offcanvas.Body>
    </Offcanvas>
      </div>
    );
};

export default NavigationBar;
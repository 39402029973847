import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InstagramLogo from '../../images/glyph-logo_May2016.svg';
import './style.css'

const Footer = props => {

    return(
        <>
       <div id="footerAlt">
           <Container>
               <Row>
                   <Col><Link to='/features'>FEATURES</Link></Col>
                   <Col><Link to='/submit'>SUBMIT</Link></Col>
                   <Col><a href="https://www.happydevilboy.com/product/sensitivecontent" target="_blank" rel="noopener noreferrer">BUY</a></Col>
                   <Col><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/sensitivecontentmag/"><img src={InstagramLogo} alt="Instagram" width="40px" /></a></Col>
               </Row>
               <Row>
               <p id="trademark">Happydevilboy Curious Publishing All Rights Reserved 2024</p>
               </Row>
           </Container>
        </div>
        </>
    )

}

export default Footer